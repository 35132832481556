//
// Modal
//

// Fluid modal

.modal-fluid {
  .modal-dialog {
    margin-top: 0;
    margin-bottom: 0;
  }
  .modal-content {
    border-radius: 0;
  }
}

// Background color variations

@each $color, $value in $theme-colors {
  .modal-#{$color} {
    @include modal-variant($value);
  }
}

.file-input {
  &__input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  &__label {
    // min-width: 150px;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    color: #98a2b3;
    font-size: 14px;
    padding: 10px 12px;
    border: 2px dashed #e0e0e0;
  }
}
.age-range {
  display: flex;
  justify-content: start;
  align-items: center;
  border: 1px solid #cad1d7;
  border-radius: 0.375rem;
  padding: 4px;
  .form-control {
    width: 70px;
    height: 31px;
    margin: 0 8px;
  }
}

.modal__btn {
  background-color: #d70365 !important;
  color: white !important;
  // padding: 12px 40px;
  border: none;
  border-radius: 3px;
  &:hover {
    background-color: #d70365;
    color: white;
  }
}

.sub__items-table {
  th {
    background: rgba(215, 3, 101, 0.03) !important;
  }
  //   width: 100%;
  //   .table_header {
  //     background: rgba(215, 3, 101, 0.03);
  //   }
  //   .table-actions {
  //     text-align: center;
  //     padding: 0px 1rem;
  //   }
}
