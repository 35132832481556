.main-content {
  position: relative;
  .sidebar-icon {
    height: 50px;
    position: absolute;
    left: -27px;
    top: 18px;
    cursor: pointer;
  }
  .sidebar-icon-2 {
    height: 50px;
    position: absolute;
    left: -17px;
    top: 18px;
    cursor: pointer;
  }

  // Navbar
  .navbar-top {
    position: fixed;
    left: 250px;
    top: 0;
    width: -webkit-calc(100% - 250px);
    z-index: 1;
    background-color: transparent;
    padding-left: 0 !important;
    padding-right: 0 !important;
    background-color: white;
    @media (max-width: 768px) {
      display: none;
    }
    &__form_group {
      position: relative;
      margin-bottom: 0 !important;
      width: 50%;
      @media (max-width: 768px) {
        display: none;
      }
      img {
        position: absolute;
        top: 30%;
        left: 2%;
      }
      .form-control {
        padding-left: 2.3rem !important ;
        padding-right: 2rem !important ;
        background: #ffffff;
        border: 1.3px solid rgba(196, 202, 205, 0.4);
        border-radius: 8px;
      }
    }
    &__profile-toggler {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 110%;
      background: #f2f8ff;
      border-radius: 8px;
    }
  }
  .navbar-top-hide {
    position: fixed;
    // left: 73px;
    left: 82px;
    top: 0;
    width: -webkit-calc(100% - 73px);
    z-index: 1;
    background-color: transparent;
    padding-left: 0 !important;
    padding-right: 0 !important;
    background-color: white;
    @media (max-width: 768px) {
      display: none;
    }
    &__form_group {
      position: relative;
      margin-bottom: 0 !important;
      width: 50%;
      @media (max-width: 768px) {
        display: none;
      }
      img {
        position: absolute;
        top: 30%;
        left: 2%;
      }
      .form-control {
        padding-left: 2.3rem !important ;
        padding-right: 2rem !important ;
        background: #ffffff;
        border: 1.3px solid rgba(196, 202, 205, 0.4);
        border-radius: 8px;
      }
    }
    &__profile-toggler {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 110%;
      background: #f2f8ff;
      border-radius: 8px;
    }
  }

  // Container
  .container-fluid {
    @include media-breakpoint-up(md) {
      padding-left: (
        $main-content-padding-x + $grid-gutter-width / 2
      ) !important;
      padding-right: (
        $main-content-padding-x + $grid-gutter-width / 2
      ) !important;
    }
  }
}

// Offsets the main content depending on the sidebar positioning

.navbar-vertical.navbar-expand {
  @each $breakpoint, $dimension in $grid-breakpoints {
    &-#{$breakpoint} {
      @include media-breakpoint-up(#{$breakpoint}) {
        // Left
        &.fixed-left + .main-content {
          margin-left: $navbar-vertical-width;
        } // Right
        &.fixed-right + .main-content {
          margin-right: $navbar-vertical-width;
        }

        &.hidden + .main-content {
          margin-left: 76px;
        }
      }
    }
  }
}
