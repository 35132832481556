.navbar .collapsing .navbar-toggler {
  pointer-events: none;
}
.navbar .nav-item .nav-link-icon.nav-link i {
  margin-right: 4px;
}

.sidebar-collapse {
  border-left: 2px solid #d0d5dd;
  margin: 8px 40px !important;
}
