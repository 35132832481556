@mixin form-control-focus($ignore-warning) {
	&:focus {
		color: $input-focus-color;
		background-color: $input-focus-bg;
		border-color: $input-focus-border-color;
		outline: 0;
		// Avoid using mixin so we can pass custom focus shadow properly
		@if $enable-shadows {
			box-shadow: $input-box-shadow, $input-focus-box-shadow;
		} @else {
			box-shadow: $input-focus-box-shadow;
		}
	}
}

@mixin form-validation-state($state, $color, $icon) {
	.#{$state}-feedback {
		display: none;
		width: 100%;
		margin-top: $form-feedback-margin-top;
		font-size: $form-feedback-font-size;
		color: $color;
	}

	.#{$state}-tooltip {
		position: absolute;
		top: 100%;
		z-index: 5;
		display: none;
		max-width: 100%; // Contain to parent when possible
		padding: 0.5rem;
		margin-top: 0.1rem;
		font-size: 0.875rem;
		line-height: 1;
		color: #fff;
		background-color: rgba($color, 0.8);
		border-radius: 0.2rem;
	}

	.form-control,
	.custom-select {
		.was-validated &:#{$state},
		&.is-#{$state} {
			border-color: $color;

			&:focus {
				border-color: $color;
				//box-shadow: 0 1px $input-focus-width 0 rgba($color, .75);
			}

			~ .#{$state}-feedback,
			~ .#{$state}-tooltip {
				display: block;
			}
		}
	}

	.timing-modal-checkbox {
		appearance: none;
		-webkit-appearance: none;
		background-color: #fff;
		border: 2px solid #5e72e4;
		width: 25px;
		height: 25px;
		padding: 5px;
		position: relative;
		cursor: pointer;
		// margin-right: 8px; /* Add margin to prevent label shrinkage */
		border-radius: 4px; /* Rounded corners */
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.timing-modal-checkbox:checked {
		background-color: #5e72e4;
	}

	.timing-modal-checkbox:checked::after {
		content: "";
		width: 6px;
		height: 12px;
		border: solid white;
		border-width: 0 2px 2px 0;
		transform: rotate(45deg);
	}

	// .timing-modal-checkbox-label {
	// 	// margin-left: 4px; /* Optional: Fine-tune label spacing */
	// }

	.form-check-input {
		.was-validated &:#{$state},
		&.is-#{$state} {
			~ .form-check-label {
				color: $color;
			}

			~ .#{$state}-feedback,
			~ .#{$state}-tooltip {
				display: block;
			}
		}
	}

	.custom-control-input {
		.was-validated &:#{$state},
		&.is-#{$state} {
			~ .custom-control-label {
				color: $color;

				&::before {
					background-color: lighten($color, 25%);
					border-color: lighten($color, 25%);
				}
			}

			~ .#{$state}-feedback,
			~ .#{$state}-tooltip {
				display: block;
			}

			&:checked {
				~ .custom-control-label::before {
					@include gradient-bg(lighten($color, 10%));
					border-color: lighten($color, 25%);
				}
			}

			&:focus {
				~ .custom-control-label::before {
					box-shadow: 0 0 0 1px $body-bg,
						0 0 0 $input-focus-width rgba($color, 0.25);
				}
			}
		}
	}

	// custom file
	.custom-file-input {
		.was-validated &:#{$state},
		&.is-#{$state} {
			~ .custom-file-label {
				border-color: $color;

				&::before {
					border-color: inherit;
				}
			}

			~ .#{$state}-feedback,
			~ .#{$state}-tooltip {
				display: block;
			}

			&:focus {
				~ .custom-file-label {
					box-shadow: 0 0 0 $input-focus-width rgba($color, 0.25);
				}
			}
		}
	}
}
