/* Style the accordion section */
.accordion__section {
  display: flex;
  flex-direction: column;
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  background-color: transparent;
  color: #007335;
  cursor: pointer;
  padding: 9px 18px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */

/* Style the accordion content title */
.accordion__title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  @media (max-width: 400px) {
    font-size: 12px;
  }
}

/* Style the accordion chevron icon */
.accordion__icon {
  margin-left: auto;
  transition: transform 0.6s ease;
}

/* Style to rotate icon when state is active */
.rotate {
  transform: rotate(180deg);
}

/* Style the accordion content panel. Note: hidden by default */
.accordion__content {
  background-color: white;
  overflow: hidden;
  transition: max-height 0.6s ease;
}

.accordion_card {
  border: 0;
  margin: 0.5rem 1rem;
  p {
    cursor: pointer;
    margin-bottom: 0.5rem;
    color: #4c4b4b;
    font-size: 12px;
    font-weight: 400;
    text-transform: capitalize;
    &:hover {
      color: #888585;
    }
  }
}
