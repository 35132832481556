.landing {
  width: 100%;
  overflow: hidden;

  .ReactFlagsSelect-module_flagsSelect__2pfa2 {
    padding-bottom: 0 !important;
    .ReactFlagsSelect-module_selectBtn__19wW7 {
      border: none !important;
      font-size: 12px !important;
      @media (max-width: 330px) {
        font-size: 10px !important;
      }
    }
    .ReactFlagsSelect-module_fullWidthOptions__1XeR6 {
      font-size: 12px !important;
      box-shadow: -3px 0px 14px -6px rgba(0, 0, 0, 0.83) !important;
      border: none !important;
      overflow: hidden !important;
      max-height: fit-content !important;
      width: fit-content !important;
      left: -1rem !important;
    }
  }

  .header {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 4;

    .top__header {
      z-index: 1; /* Ensure it's above the LandingNavbar */
      transition: transform 0.3s ease, opacity 0.3s ease;
      &_hidden {
        transform: translateY(-10%);
        opacity: 0;
        height: 0px;
      }

      p {
        font-size: 12px;
        @media (max-width: 330px) {
          font-size: 10px;
        }
      }
    }
    .nav_brand {
      color: $landing-background;
      margin-bottom: 0;
      margin-top: 13px;
    }
    .nav_links {
      cursor: default;
      &:hover {
        color: #9da6ba;
      }
    }
    .nav_cards {
      position: absolute;
      z-index: 4;
      margin-top: 0.2rem;
      min-width: 17rem;
      max-width: 50rem;
      width: -webkit-fill-available;
      border: 0;
      padding: 1rem 2rem;
      box-shadow: 0px 6px 17px -3px rgba(36, 35, 35, 0.7);
      &__feature {
        display: flex;
        justify-content: start;
        align-items: center;
        .image {
          width: 24px;
          height: 28px;
        }
        h4 {
          font-size: 12px;
          color: $landing-background !important;
        }
      }
      p {
        cursor: pointer;
        margin-bottom: 0.5rem;
        color: #4c4b4b;
        font-size: 12px;
        font-weight: 400;
        text-transform: capitalize;
        &:hover {
          color: #888585;
        }
      }
    }
    .nav_pricing {
      color: $landing-background;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      cursor: pointer;
      @media (max-width: 400px) {
        font-size: 12px;
      }
    }
    .nav_learn__text {
      color: black;
      text-transform: capitalize;
      cursor: pointer;
      @media (max-width: 566px) {
        font-size: 10px;
        margin-bottom: 0.5rem;
      }
    }
    .nav_heading {
      color: #9da6ba;
      font-size: 12px;
      margin: 1rem;
    }
    .dashed-line {
      border: 1.3px dashed #888585;
      margin: 1rem;
    }
    .nav_buttons {
      @media (max-width: 890px) {
        display: none;
      }
      @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        margin: 0 1rem;
        button {
          padding: 0.5rem !important;
        }
      }
    }
    .navbar-collapse {
      @media (max-width: 768px) {
        max-height: 100vh !important;
        overflow-y: scroll;
        padding: 1rem 0 !important;
        padding-bottom: 2rem !important;
      }
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  .landing-hero {
    margin: 10rem 0 0;
    &__top-row {
      @media (max-width: 992px) {
        flex-direction: column;
        margin-bottom: 4rem;
      }
    }
    .hero__right {
      position: relative;
      &-bg {
        width: 100%;
        border-radius: 288px 0px 227px 0px;
        position: absolute;
        height: auto;
        right: -2rem;
        top: -12px;
        z-index: -1;
        overflow: hidden;
        rotate: -5deg;
      }
      &-yellow_leaf {
        mix-blend-mode: multiply;
        z-index: 3;
        max-width: 13vw;
        position: absolute;
        top: 4rem;
        right: 15px;
      }
      &-peach_leaf {
        mix-blend-mode: multiply;
        z-index: 3;
        max-width: 13vw;
        position: absolute;
        top: 8rem;
        overflow: hidden;
        left: 3rem;
        rotate: 306deg;
      }
      &-green_leaf {
        mix-blend-mode: multiply;
        z-index: 3;
        overflow: hidden;
        position: absolute;
        width: 28vh;
        top: 62%;
        left: 32rem;
        rotate: 78deg;
      }

      @media (max-width: 992px) {
        display: flex;
        justify-content: center;
        align-items: center;
        &-bg {
          position: inherit;
          top: 0;
          right: 0;
          z-index: 0;
          border-radius: 0;
          rotate: 0deg;
          width: 80%;
          opacity: 1 !important;
        }

        &-peach_leaf {
          top: 54%;
          overflow: hidden;
          left: 8%;
          rotate: 0deg;
          max-width: 100%;
          width: 16%;
          opacity: 1 !important;
        }
        &-yellow_leaf {
          max-width: 100%;
          width: 15%;
          top: 56%;
          rotate: 314deg;
          right: 8%;
          opacity: 1 !important;
        }
        &-green_leaf {
          max-width: 100%;
          width: 16%;
          top: 54%;
          rotate: 69deg;
          left: 75%;
        }
      }
    }
    .hero__left {
      padding: 4rem 0;
      &-top {
        &_tag {
          font-size: 16px;
          line-height: 24px;
          color: #272727;
          font-weight: 500;
        }
        &_tagline {
          font-size: 40px;
          font-weight: 600;
          line-height: 50px;
          color: $landing-background;
          margin-bottom: 1rem;
        }
      }
      &-bottom {
        &_tagline {
          font-size: 22px;
          font-weight: 500;
          line-height: 30px;
          font-style: normal;
        }
        &_tag {
          font-size: 16px;
          line-height: 24px;
          color: #272727;
          font-weight: 400;
        }
      }
    }
  }
  .testimonials {
    background: linear-gradient(
      to bottom,
      $landing-background 70%,
      transparent 50%
    );
    // background: linear-gradient(to bottom, #0f615d 70%, transparent 50%);
    padding-top: 4rem;
  }
  // .partners {
  //   margin: 2rem 0;
  //   .parnters_text {
  //     font-size: 30px;
  //     font-style: normal;
  //     font-weight: 400;
  //     color: #ffffff;
  //     line-height: 40px;
  //     margin-bottom: 2rem;
  //     span {
  //       color: #fc968b;
  //     }
  //   }
  // }

  .industries {
    padding: 0 !important;
    border-radius: 12px;
    max-height: 13rem;
    &:hover {
      .hover__background {
        z-index: 3;
        &__leaves {
          display: block;
        }
      }
    }
    .hover__background {
      position: absolute;
      background: $landing-background;
      // background: url("../img/LandingPage//bg-green.png");
      // background-repeat: no-repeat;
      // background-color: transparent;
      border-radius: 12px;
      background-position: 0;
      background-size: 160%;
      cursor: pointer;
      height: 100%;
      width: 100%;

      &__leaves {
        display: none;
        position: absolute;
        top: -6%;
        right: -10%;
        mix-blend-mode: multiply;
        width: 66%;
        height: auto;
      }
      &__text {
        color: #fff;
        padding: 2rem;
        position: absolute;
        left: -5%;
        top: 14%;
        @media (max-width: 650px) {
          font-size: 14px;
        }
        @media (max-width: 575px) {
          font-size: 16px;
          // top: 30%;
          padding: 3rem;
        }
        @media (max-width: 350px) {
          font-size: 15px;
          // top: 30%;
          padding: 2rem;
        }
      }
    }
    &_main {
      border-radius: 12px;
      position: relative;
      height: 100%;
      cursor: pointer;
      img {
        border-radius: 12px;
        width: 100%;
        height: 100%;
      }
      &_overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 12px;
        background-color: rgba(0, 0, 0, 0.6);
        z-index: 1;
      }
      &_text {
        position: absolute;
        bottom: 0;
        left: 4%;
        letter-spacing: 0.025em;
        color: #fff;
        font-size: 1rem;
        z-index: 1;
      }
    }
    &_title {
      width: 100%;
      background-color: $landing-background;
      // background-color: #0f615d;
      height: 100%;
      border-radius: 12px;
      // padding: 8% 5%;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 575px) {
        padding: 4rem 1rem;
      }
      h1 {
        color: white;
        padding-left: 1rem;
        @media (max-width: 690px) {
          font-size: 22px;
        }
      }
    }
  }
  .features {
    background: $landing-background;
    // background: #0f615d;
    h1 {
      font-size: 40px;
      font-weight: 400;
      line-height: 45px;
      color: #fff;
      // span {
      //   color: #fc968b;
      // }
      @media (max-width: 992px) {
        font-size: 35px;
        line-height: 40px;
        padding: 0 1rem;
      }
      @media (max-width: 525px) {
        font-size: 30px;
        line-height: 35px;
        margin: 0.5rem 0 !important;
      }
      @media (max-width: 350px) {
        font-size: 25px;
        line-height: 30px;
      }
    }
  }
  .landing_footer {
    background-color: #f2f2f2;
    padding: 2rem 0;
    .ReactFlagsSelect-module_fullWidthOptions__1XeR6 {
      top: -13rem;
    }
    &_text {
      font-size: 12px;
      @media (max-width: 330px) {
        font-size: 10px !important;
      }
    }
    &_icons {
      width: 28px;
      height: 28px;
      margin: 5px;
      border-radius: 100%;
    }
  }
  .outlined_button {
    background: white !important;
    color: $outline-color !important;
    border: 3px solid $outline-color !important;
    box-shadow: none !important;
    &:hover {
      background-color: $outline-color !important;
      color: white !important;
      transform: translateY(0px) !important;
    }
  }
}
