.flickty_carosel {
  .carosel_card {
    width: 30%;
    padding: 1rem;
    box-shadow: 0px 5px 14px 3px rgba(168, 162, 162, 0.5);
    margin-bottom: 1rem;
    &-img {
      width: 100%;
      // height: 25% !important;
      max-height: 22rem;
    }

    &-description {
      font-size: 15px;
      font-style: normal;
      font-weight: 300;
      color: #505f76;
      line-height: 22px;
    }
    &-title {
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      color: #0f615d;
      line-height: 22px;
    }
    &-address {
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      color: #0f615d;
      line-height: 22px;
    }
    &-tag {
      background: #ffba00;
      padding: 3px 8px;
      border-radius: 12px;
      font-size: 12px;
      color: white;
    }
    &-link {
      color: #fc968b;
      //   cursor: pointer;
    }

    @media (max-width: 992px) {
      width: 38%;
    }
    @media (max-width: 768px) {
      width: 50%;
    }
    @media (max-width: 525px) {
      width: 70%;
    }
    @media (max-width: 350px) {
      width: 15rem;
    }
  }

  .flickity-enabled {
    position: relative;
    margin-top: 5rem;
  }

  .flickity-enabled:focus {
    outline: none;
  }

  .flickity-viewport {
    overflow: hidden;
    position: relative;
    height: 100%;
  }

  .flickity-slider {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  /* draggable */

  .flickity-enabled.is-draggable {
    -webkit-tap-highlight-color: transparent;
    tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .flickity-enabled.is-draggable .flickity-viewport {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab;
  }

  .flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }

  /* ---- flickity-button ---- */

  .flickity-button {
    position: absolute;
    background: #fc968b;
    border: none;
    opacity: 0.7;
  }

  .flickity-button-icon {
    fill: white;
  }

  /* ---- previous/next buttons ---- */

  .flickity-prev-next-button {
    top: 50%;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    /* vertically center */
    transform: translateY(-50%);
  }

  .flickity-prev-next-button.previous {
    left: 10px;
  }
  .flickity-prev-next-button.next {
    right: 10px;
  }
  .flickity-rtl .flickity-prev-next-button.previous {
    left: auto;
    right: 10px;
  }
  .flickity-rtl .flickity-prev-next-button.next {
    right: auto;
    left: 10px;
  }

  .flickity-prev-next-button .flickity-button-icon {
    position: absolute;
    left: 20%;
    top: 20%;
    width: 60%;
    height: 60%;
  }

  .flickity-page-dots {
    display: none;
  }
}
