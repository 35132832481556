.login {
  font-family: "poppins", sans-serif;
  &__password {
    position: relative;
    .eye_icon {
      position: absolute;
      top: 31%;
      right: 3%;
      cursor: pointer;
    }
  }
  .card {
    border-radius: 8px !important;
    background: #ffffff !important;
  }
  .form_label {
    color: #333333;
    font-weight: 500;
    // font-family: "Montserrat";
    font-style: normal;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: -0.005em;
    margin: 0;
    // position: relative;
  }
  .form-control {
    background: #ffffff !important;
    border: 1px solid #e0e0e0 !important;
    border-radius: 5px !important;
  }
  .title {
    font-weight: 700;
    font-size: 19px;
    line-height: 32px;
    color: #212121;
  }
  .typography {
    color: #848484;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
  }

  &__btn {
    background-color: #d70365 !important;
    color: #ffffff !important;
    border-radius: 5px !important;
    border: 0 !important;
  }
}
.cursor-pointer {
  cursor: pointer !important;
}
.menu-option:hover {
  background-color: #d70365 !important;
  color: #ffffff;
  h2 {
    color: #ffffff;
  }
}
.menu-option:active {
  background-color: #d70365 !important;
  color: #ffffff;
}
.side-offcanvas {
  transition: left 0.8s ease !important;
  // background-color: #d70365 !important;
  .nav-link {
    &:hover {
      border: none !important;
      color: #525f7f;
    }
  }
}

.set-hover {
  &:hover {
    // background-color: #d70365;
    // color: #ffffff;
    border-radius: 6px;
  }
}
.breaker {
  margin: 1px;
}

.row-height {
  height: 82% !important;
}
.rotate {
  transform: rotate(180deg);
  transition: transform 0.5s ease-in-out;
}
.rotate-back {
  transform: rotate(0deg);
  transition: transform 0.5s ease-in-out;
}
.no-spin::-webkit-inner-spin-button,
.no-spin::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
