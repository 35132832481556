//
// Cards
//

@import "cards/card";
@import "cards/card-profile";
@import "cards/card-animations";
@import "cards/card-stats";

.card-body {
  padding: 1.2rem !important;
}
