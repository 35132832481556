/* .content {
  background: linear-gradient(87deg, #131516 0, #131516 100%) !important;
}
.bg-default {
  background: linear-gradient(#fbfbfb 0, #fbfbfb 100%) !important;
}
.fill-default {
  fill: #fbfbfb !important;
} */

.main-content-auth {
  width: 100%;
  min-height: 100vh;
  background: url("../assets/img/login.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  box-shadow: inset 0 0 0 2000px rgba(255, 255, 255, 0.4);
  overflow: hidden;
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
}
.admin-login {
  width: 100%;
  height: 100vh;
  background: #ffffff;
  @media (max-width: 768px) {
    height: auto;
  }
  &__row {
    background: white;
    height: 83vh;
    align-items: center;
    @media (max-width: 768px) {
      height: auto;
    }
    .login_img {
      width: 100%;
    }
  }
  &__form {
    margin-top: 20px;
    font-family: Poppins;
    .form__img {
      width: 13%;
    }
    .title {
      font-size: 14px !important;
    }
    .typography {
      font-size: 11px;
    }
    .form_label {
      color: #333333;
      font-weight: 500;
      font-family: "Montserrat";
      font-style: normal;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: -0.005em;
      margin: 0;
      // position: relative;
    }
    .form-control {
      background: #ffffff !important;
      border: 1px solid #e0e0e0 !important;
      border-radius: 5px !important;
    }
  }
}

// .bg-default {
//   background: linear-gradient(#fbfbfb 0, #fbfbfb 100%) !important;
// }
// .fill-default {
//   fill: #fbfbfb !important;
// }
